var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.items.length > 0)?_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"60vmin"},attrs:{"center":_vm.center,"zoom":15,"map-style-id":"roadmap","options":_vm.mapOptions}},[_vm._l((_vm.items),function(risk,index){return _c('GmapMarker',{key:risk.id,attrs:{"position":risk.position,"clickable":true,"placeholder":risk.label},on:{"click":function($event){return _vm.toggleInfoWindow(risk, index)}}})}),_c('GmapInfoWindow',{attrs:{"options":_vm.infoOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen = false}}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.infoContent)}})])],2):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"font-family":"'IBM Plex Sans Thai'","font-style":"normal","font-weight":"600","font-size":"24px","line-height":"40px"}},[_vm._v(" สรุปจุดเสี่ยง ")])])],1),_c('v-row',{staticClass:"d-flex justify-space-between"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-inner-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ExportTable1',{attrs:{"data":_vm.items,"formDate":_vm.date,"toDate":_vm.date1}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"#2AB3A3","dark":""},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v("เพิ่มจุดเสี่ยง")])],1)],1),_c('br'),_c('v-data-table',{staticClass:"mt-3 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"footer-props":{
      'items-per-page-options': [20, 30, 40, 50],
    },"items-per-page":20},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertDate(item.createdAt)))])]}},{key:"item.updatedAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertDate(item.updatedAt))+" ")]}},{key:"item.image_url",fn:function(ref){
    var item = ref.item;
return [(item.image_url)?_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.goToPic(item.image_url)}}},[_vm._v("mdi-image-outline")]):_c('p',[_vm._v("-")])]}},{key:"item.position",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.onClickMarker(item.position)}}},[_vm._v("mdi-map-marker")])],1)]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.DeleteBanner(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"item.export",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-icon',{staticClass:"mx-2",on:{"click":function($event){return _vm.goToPrint(item.id)}}},[_vm._v("mdi-text-box-outline")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }